.VirtualWorlds_Container{
   
    h2:hover{
        animation: shake 10s !important;
      }
      @keyframes shake {
        0% { transform: skewX(-15deg); }
        5% { transform: skewX(15deg); }
        10% { transform: skewX(-15deg); }
        15% { transform: skewX(15deg); }
        20% { transform: skewX(0deg); }
        100% { transform: skewX(0deg); }  
      }
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    p{
        max-width: 500px;
        margin: 10px 0 20px 0;
    }
    .shallow{
        opacity: .6;
    }
    .renderedImages{
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        max-width: 500px;
        .images{
            width: 90px;
            height: 90px;
        }
    }
    .mainImage{
        width: 500px;
        height: 500px;
    }
    .title{
        font-family: Meshroom;
        font-size: 1.8rem;
        letter-spacing: 1px;
        margin: 10px 0;
    }
    .chosenImage{
        display: flex;
        align-items: center;
        button{
            background-color: transparent;
            border: none;
            cursor: pointer;
            margin: 0 7vw;
        }
    }
}

// ----------------- Media Query ------------------- //

@media only screen and (max-width: 860px) {
    .VirtualWorlds_Container{
        padding: 0 30px;
        p{
            max-width: 60vw;
        }
        .renderedImages{
            max-width: 60vw;
            .images{
                width: 10vw;
                height: 10vw;
            }
        }
        .mainImage{
            width: 60vw;
            height: 60vw;
        }
        .chosenImage{
            button{
             margin: 0 4vw;
            }
        }
        
    }
 
}
@media only screen and (max-width: 600px) {
    .VirtualWorlds_Container{
        padding: 0 30px;
        p{
            max-width: 90%;
        }
        .title{
            font-family: Meshroom;
            font-size: 1.6rem;
            letter-spacing: 1px;
        }
 
    }
 
}
@media only screen and (max-width: 400px) {
    .VirtualWorlds_Container{
     
        .title{
            font-size: 1.4rem;
        }
 
    }
 
}
