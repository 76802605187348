
.Team_Container{
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 200px;
   height: 600px;
   h2{
      margin-top: -50px;
      margin-bottom: 80px;
   }
   .purple_bg{
      height: 600px;
      width: 600px;
      position: absolute;
      z-index: -1;
   }
   .TeamMember_Container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom:30px;
      .memberImage{
         height: 80px;
         margin-right: 30px;
      }
      .p{
         font-family: Gotham;
         letter-spacing: 1px;
         font-size: 1rem;
         font-weight: bold;
         line-height: 1.5rem;
         text-align: left !important;
      }
      .memberInfo{
         display: flex;
         flex-direction: column;
         width: 340px;
         align-items: flex-start;
         margin-right: -30px;
         p{
            margin: 0;
         }
      }
   }
}
h2:hover{
   animation: shake 10s !important;
 }
// ----------------- Media Query ------------------- //

@media only screen and (max-width: 700px) {
   .Team_Container{
      .purple_bg{
         width: 100%;
         height: 500px;
      }
      .TeamMember_Container{
         margin-bottom: 20px;
         .memberImage{
            height: 60px;
            justify-self: flex-start;
            margin-right: 20px;
         }
         .memberInfo{
         max-width: 50vw;
            p{
               font-size: 15px;
               text-align: left;
            }
         }
      }
   }
}
@media only screen and (max-width: 500px) {
   .Team_Container{
      margin-top: 150px;
      .purple_bg{
         height: 480px;
      }
      .TeamMember_Container{
         align-items: flex-start;
         .memberImage{
            margin-right: 10px;
            height: 50px;
         }
        
      }
      .teamMembers{
         margin-top: 10px;
      }
   }
}
@media only screen and (max-width: 310px) {
   .Team_Container{
      .purple_bg{
         height: 550px;
      }
   }
}