body {
  margin: 0;
  background: linear-gradient(#52027B,#320759,#173868,#42F1E7,#4FA2C5,#93DBA9,#4FA2C5);
}

html {
  scroll-behavior: smooth;
  
}
:target:before {
  display: block;
  position: relative;
  top: -120px; 
  visibility: hidden;
}
@font-face {
  font-family: Gotham;
  src: url(../src/assets/font\ /Gotham-Light.otf);
  src: url(../src/assets/font\ /Gotham-Book-Regular.otf)
       url(../src/assets/font\ /Gotham-Medium.otf)
       url(../src/assets/font\ /Gotham-Medium-copy.otf)
       url(../src/assets/font\ /Gotham-Medium-Regular.ttf)
       url(../src/assets/font\ /GothamHTF-Light.otf);
}

@font-face {
  font-family: Meshroom;
  src:  url(../src/assets/font\ /TheMeshroomRegular.ttf);
}
;