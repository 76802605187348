.FAQ_Container{
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .Question_Container{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        p{
            font-size: 16px;
        }
        .row1{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 600px;
            border-bottom: 1px solid black;
            .button{
                cursor: pointer;
                font-size: 30px;
                width: 30px;
            }
        }
    .text{
        width: 600px;
        text-align: left;
        margin-bottom: 0;
    }
    }
}
    
// ----------------- Media Query ------------------- //

@media only screen and (max-width: 700px) {
    .FAQ_Container{
        .Question_Container{
            p{
                font-size: 12px;
            }
            .row1{
                width: 80vw;
                height: 80px;
            }
        .text{
            width: 80vw;
        }
        }
    }
}
@media only screen and (max-width: 700px) {
    
}
h2:hover{
    animation: shake 10s !important;
  }